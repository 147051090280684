<template>
  <section class="services-slider">
    <div class="wrapper">
      <h2 class="services-slider__title">{{ title }}</h2>
      <ClientOnly>
        <div class="swiper-container">
          <Swiper v-bind="swiperSettings" class="services-slider__slider">
          <SwiperSlide
            v-for="(item, index) of services"
            :key="index"
            class="services-slider__slide"
          >
            <UIPictureTag
              :is-visible="true"
              :image-webp="`${url}${item.image.webp}`"
              :image-seo="item.imageSeo"
              :image-original="`${url}${item.image.source}`"
            />
            <div class="services-slider__link">{{ item.title }}</div>
            <UIButton
              class="services-slider__button"
              type="link"
              variant="primary"
              size="l"
              :link="{ name: 'home', params: { chapters: useSlugTransform(item.slug) } }"
            >
              Об услуге
            </UIButton>
          </SwiperSlide>
        </Swiper>
        </div>

      </ClientOnly>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
import './styles.scss';
import type { ImageItem, ImageSEO } from '~/types/pages';
import { useRuntimeConfig } from '#imports';
import { useSlugTransform } from '~/composables/useSlugTransform';
interface Props {
  title: string;
  services: {
    image: ImageItem;
    imageSeo: ImageSEO;
    title: string;
    slug: string;
  }[];
}

defineProps<Props>();
const url = useRuntimeConfig().public.siteUrl;

const swiperSettings = {
  slidesPerView: 1,
  spaceBetween: 16,
  createElements: true,
  autoHeight: false,
  pagination: {
    clickable: true,
  },
  navigation: true,
  modules: [Navigation, Pagination],
  breakpoints: {
    1025: {
      slidesPerView: 4,
      spaceBetween: 36,
      centeredSlides: false,
    },
    769: {
      slidesPerView: 3,
      spaceBetween: 24,
      centeredSlides: false,
    },
    475: {
      slidesPerView: 2,
      spaceBetween: 16,
      centeredSlides: false,
    },
  },
};
</script>

<style scoped></style>
